import React from 'react'
import './Trainings.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const Trainings = ({description,image}) => {
  return (
    <section className='training' style={{
      background: `url("${image.file.url}") center center no-repeat`,
      backgroundSize: 'cover'
    }}>
    <div className='training__content container container--medium'>
      {documentToReactComponents(JSON.parse(description.raw))}
    </div>
    </section>
  )
}

export default Trainings
