import React from 'react'
import './ContentHome.scss'
import BannerHome from "../BannerHome/BannerHome";
import ServiceContent from "../../molecules/ServiceContent/ServiceContent";
import Trainings from "../../molecules/Trainings/Trainings";
import Clients from "../../molecules/Clients/Clients";
import Contact from "../Contact/Contact";
import {graphql, useStaticQuery} from "gatsby"
import VideoOur from "../../molecules/videoOur/videoOur";

const ContentHome = () => {


  const {allContentfulSeccionHome} = useStaticQuery(
    graphql`
        query Home_Query {
            allContentfulSeccionHome {
                nodes {
                    descripcionPrincipal {
                        raw
                    }
                    servicioHome {
                        descripcion {
                            raw
                        }
                        imagen {
                            file {
                                url
                            }
                            gatsbyImageData
                        }
                    }
                    capacitaciones {
                        descripcion {
                            raw
                        }
                        imagen {
                            file {
                                url
                            }
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `
  )
  const contentInfo = allContentfulSeccionHome.nodes[0]

  return (
    <>
      <BannerHome description={contentInfo.descripcionPrincipal}/>
      {/*<VideoOur />*/}
      <ServiceContent
        direction='left'
        description={contentInfo.servicioHome.descripcion}
        image={contentInfo.servicioHome.imagen}
      />
      <Trainings
        image={contentInfo.capacitaciones.imagen}
        description={contentInfo.capacitaciones.descripcion}
      />
      <Clients/>
      <Contact/>
    </>
  )
}

export default ContentHome
