import React from 'react'
import './ServiceContent.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const ServiceContent = ({direction, description, image}) => {
  return (
    <section className={`service-ccl ${direction}`}>
      <section className={`service-ccl__wrapper ${direction}`}>
        <div className={`service-ccl__description ${direction}`}>
          <div className='service-ccl__description-wrapper'>
            {documentToReactComponents(JSON.parse(description.raw))}
          </div>
        </div>
        <div className={`service-ccl__img ${direction}`}>
          <img src={image.file.url} alt='Servicio tecnico'/>
        </div>
      </section>
    </section>
  )
}

export default ServiceContent
