import React from 'react'
import './BannerHome.scss'
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"
import useMobile from "../../../hooks/useMobile";
import Slide from "../../molecules/Slide/Slide";
// import { BLOCKS, MARKS } from "@contentful/rich-text-types"


// const options = {
//   renderNode: {
//     [BLOCKS.HEADING_1]: (node, children) => <h1 className="principal-info__title">{children}</h1>,
//     [BLOCKS.PARAGRAPH]: (node, children) => <p className="principal-info__paragraph">{children}</p>
//   }
// }

const BannerHome = ({image, description}) => {

  const isMobile = useMobile()
  return (
    <section className='banner-home'>
      <Slide />


      {/*<div className='banner-home__slide'>*/}
      {/*  <img src={image[isMobile ? 1 : 0].file.url} className='banner-home__slide-img'*/}
      {/*       alt={image[isMobile ? 1 : 0].description}/>*/}
      {/*</div>*/}

      <div className='banner-home__wrapper container'>
        <div className='banner-home__content '>
          {documentToReactComponents(JSON.parse(description.raw))}


        </div>
      </div>
    </section>
  )
}

export default BannerHome
