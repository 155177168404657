import React from 'react'
import './Slide.scss'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {graphql, useStaticQuery} from 'gatsby'

import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import useMobile from "../../../hooks/useMobile";

const Slide = () => {
  const isMobile = useMobile()


  const {allContentfulSeccionHome} = useStaticQuery(
    graphql`
        query Slide_Query {
            allContentfulSeccionHome {
                nodes {
                    banner {
                        imagen {
                            description
                            gatsbyImageData(quality: 100)

                        }
                        titulo
                        redireccion
                    }
                }
            }
        }
    `
  )

  const banners = allContentfulSeccionHome.nodes[0].banner


  const options = {
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    dots: true,
    centerMode: false,
    dotsClass: 'slick-dots-banner',
  }


  return (
    <section className="section-slide-banner">
      <Slider {...options}>
        {banners.map((image, index) => {
          let link = image.redireccion.length >3
          return (
            <div
              onClick={e => {
                e.preventDefault()
                if(link){
                  window.open(
                    image.redireccion,
                    '_blank'
                  )

                }



              }}
              className={`slide-container ${link ? 'link' : ''}`}
              // href={image.redireccion}
              key={`banner-${index + 1}`}
            >
              <GatsbyImage
                className="slide-container__image"
                image={getImage(image.imagen[isMobile ? 1 : 0])}
                alt="Banner"
                qua
              />
            </div>
          )
        })}
      </Slider>
    </section>
  )
}

export default Slide
